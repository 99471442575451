import { initTier1Common } from '@/pages/_shared/common/tier1'
import { initTier1NotCheckout } from '@/pages/_shared/not-checkout/tier1'
import { initPageTier } from '@/pages/_shared/tier-utils'

export const initTier1 = async (): Promise<void> => {
  await initPageTier(1, [initTier1Common, initTier1NotCheckout])
  // `webpackPrefetch` prefetches the next tier at `Lowest` network priority when the current tier is loaded,
  // even before the following `import()` is executed.
  import(
    /* webpackChunkName: '@/home/tier2', webpackPrefetch: true */ './tier2'
  ).then(({ initTier2 }) => initTier2())
}

// TODO: Once tiered loading is on multiple pages, test to see whether common tier 1 and execute immediately after
//   it's cached from the first page view and the user visits a subsequent page with a different layout.
//   Would it help caching and enable immediate execution of common to have a separate common entry point?
initTier1()
